<template>
    <div class="messages">
        <ul ref="messages">
            <Message v-for="item in messages" :key="item.sid" :direction="item.author === identity ? 'incoming' : 'outgoing'" :message="item" />
        </ul>
    </div>
</template>

<script>
import Message from "@/components/Conversation/Message";
export default {
    props: {
        identity: {
            type: String,
            default: ""
        },
        messages: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            mediaUrl: ""
        };
    },
    components: {
        Message
    },
    methods: {},
    mounted() {}
};
</script>

<style lang="less" scoped>
.messages {
    flex-basis: 100%;
    flex-grow: 2;
    flex-shrink: 1;
    padding-bottom: 92px;
    > ul {
        padding: 0 40px;
    }
}
</style>
