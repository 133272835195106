<template>
    <div class="qa-index">
        <md-tabs @change="tabChange" ref="tabs">
            <md-tab :md-active="tabIndex == 0" md-label="Tickets">
                <q-a-list :my="false" v-if="tabIndex == 0"></q-a-list>
            </md-tab>
            <md-tab :md-active="tabIndex == 1" :md-disabled="page != 'detail'" md-label="Ticket Detail">
                <q-a-detail :id="id" v-if="tabIndex == 1"></q-a-detail>
            </md-tab>
            <md-tab :md-active="tabIndex == 2" :md-disabled="page != 'edit'" md-label="Ticket Edit">
                <q-a-edit :id="id" v-if="tabIndex == 2"></q-a-edit>
            </md-tab>
            <md-tab v-if="!vendorMode" :md-active="tabIndex == 3" md-label="Messages">
                <Conversation v-if="tabIndex == 3"></Conversation>
            </md-tab>
            <!-- <template v-if="!vendorMode">
                <md-tab :md-active="tabIndex == 3" :md-disabled="page != 'conversation'" md-label="Texts Detail">
                    <Conversation v-if="tabIndex == 3"></Conversation>
                </md-tab>
            </template> -->
        </md-tabs>
    </div>
</template>
<script>
import QAList from "@/components/QA/QAList";
import QADetail from "@/components/QA/QADetail";
import QAEdit from "@/components/QA/QAEdit";
import Conversation from "@/components/Conversation/Conversation";

import { mapGetters, mapActions } from "vuex";

export default {
    name: "qa-index",
    props: {
        page: {
            type: String,
            default: "list"
        },
        id: {
            type: String,
            default: ""
        }
    },
    components: {
        QAList,
        QADetail,
        QAEdit,
        Conversation
    },
    data() {
        return {
            tabIndex: 0,
            ready: false
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole",
            vendorMode: "vendorMode"
        }),
        showTexts() {
            return this.hasRole("manager");
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                switch (this.page) {
                    case "list":
                        this.tabIndex = 0;
                        break;
                    case "detail":
                        this.tabIndex = 1;
                        break;
                    case "edit":
                        this.tabIndex = 2;
                        break;
                    case "conversation":
                        this.tabIndex = 3;
                        break;
                }

                setTimeout(() => {
                    this.ready = true;
                }, 0);
            }
        }
    },
    methods: {
        tabChange(index) {
            if (!this.ready) {
                return;
            }

            if (index == 0) {
                this.$router.push("/admin/qas/manage");
            }
            if (index == 1) {
                this.$router.push(`/admin/qas/detail/${this.id}`);
            }
            if (index == 2) {
                this.$router.push(`/admin/qas/edit/${this.id}`);
            }
            if (index == 3) {
                this.$router.push("/admin/qas/Conversation");
            }
        }
    },
    created() {}
};
</script>
