<template>
    <div class="conversation" :class="{ mobile: isMobile }" ref="conversation" :style="{ height: conversationHeight }">
        <div v-if="conversationHeight" class="wrap">
            <md-toolbar class="md-transparent">
                <md-button class="md-fab md-mini md-primary" @click="toggleMessageList" v-show="!showMessageList" v-if="isMobile">
                    <md-icon>message</md-icon>
                </md-button>
                <h2 class="md-title">Conversations</h2>
                <div style="flex: 1;"></div>
                <md-button class="md-raised md-primary" :class="{ 'new-room-btn': !showMessageList }" @click="showAddNewConversation">
                    <md-icon>add</md-icon>
                    <span>{{ isMobile ? "New" : "New conversation" }}</span>
                </md-button>
            </md-toolbar>
            <div v-if="!conversations.length || connectionState != 'connected'" style="margin:auto">
                <p v-if="!conversations.length && connectionState == 'connected'">No Data Found</p>
                <p style="text-align:center;color:#afafaf">{{ connectionState || "loading" }}</p>
            </div>
            <div class="main" v-else>
                <div class="sidebar" :class="{ show: showMessageList }">
                    <md-list class="custom-list ">
                        <md-list-item
                            v-for="(item, index) in conversations"
                            :key="index"
                            class="summary-item"
                            :class="{ unread: item.unreadMessagesCount > 0 || item.unreadMessagesCount === null, active: selectedIndex === index }"
                            @click="selectedIndex = index"
                        >
                            <div style="display:flex">
                                <p class="name md-body-1">{{ friendlyName(item.friendlyName) }}</p>
                            </div>
                            <div style="display:flex;align-items:center;margin-left:auto;">
                                <p class="time md-caption">{{ messageTime(item.lastMessage ? item.lastMessage.dateCreated : item.dateUpdated) }}</p>
                                <div class="badge" v-if="item.unreadMessagesCount > 0 || item.unreadMessagesCount === null">{{ unreadMessagesCount(item.unreadMessagesCount) }}</div>
                            </div>
                        </md-list-item>
                    </md-list>
                </div>
                <div class="content" v-if="selectedConversation">
                    <div class="header">
                        <span class="name"> {{ selectedConversation.friendlyName }} </span>
                        <md-button @click="showRemoveConversation"><md-icon>delete</md-icon></md-button>
                    </div>
                    <div class="messages">
                        <div class="pre-page" v-if="hasPrev">
                            <md-button :disabled="loading" @click="handleGetPrevPage"> <md-icon>keyboard_double_arrow_up</md-icon>Load More Message </md-button>
                        </div>
                        <Messages :identity="identity" :messages="messages" />
                        <div class="anchor" id="anchor"></div>
                    </div>
                    <div class="footer input-wrap">
                        <form novalidate @submit.prevent="handleSendMessage">
                            <md-input-container style="padding-left:10px;">
                                <md-input placeholder="Type your message here..." v-model="messageValue" @keyup.enter="handleSendMessage"></md-input>
                                <md-button class="md-raised md-primary custom_button" :class="{ 'md-icon-button': isMobile }" @click="handleClickUploadImage" :disabled="sending">
                                    <md-icon>image</md-icon>
                                    <input type="file" name="file" id="file" class="img-input" ref="file" accept="image/png,image/jpg,image/jpeg,image/gif" @change="handleSelectedImage" />
                                </md-button>
                                <Submit class="custom_button" :className="{ 'md-icon-button': isMobile }" icon="send" text=""></Submit>
                            </md-input-container>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <md-dialog md-open-from="#custom" md-close-to="#custom" ref="newConversation">
            <md-dialog-content>
                <md-input-container>
                    <md-input placeholder="Enter phone number" v-model="phoneValue" @keyup.enter="handleAddNewConversation"></md-input>
                </md-input-container>
            </md-dialog-content>

            <md-dialog-actions>
                <md-button class="" @click="hideAddNewConversation">Cancel</md-button>
                <md-button class="md-primary" @click="handleAddNewConversation">Submit</md-button>
            </md-dialog-actions>
        </md-dialog>
        <md-dialog md-open-from="#custom" md-close-to="#custom" ref="delConversation">
            <md-dialog-content>
                <p>Please Confirm you would like to remove this chat</p>
            </md-dialog-content>

            <md-dialog-actions>
                <md-button class="" @click="hideRemoveConversation">Cancel</md-button>
                <md-button class="md-primary" @click="handleRemoveConversation(selectedConversation.sid)">Submit</md-button>
            </md-dialog-actions>
        </md-dialog>
    </div>
</template>

<script>
import moment from "moment";
import Submit from "@/components/Submit";
import Messages from "@/components/Conversation/Messages";
import { Client as ConversationsClient } from "@twilio/conversations";
import { setTimeout } from "timers";
export default {
    components: {
        Submit,
        Messages
    },
    data() {
        return {
            isInit: false,
            initTimer: 0,
            loading: false,
            sending: false,
            resizeTimer: 0,

            isMobile: false,
            showMessageList: true,

            conversationHeight: "",
            conversationsClient: null,

            token: "",
            identity: "",
            TWILIO_PHONE_NUMBER: "",

            // current conversation
            selectedIndex: 0,

            connectionState: "",
            conversations: [], // all conversations
            messagesPaginators: [], // messages in page
            messages: [], // new messages
            messageValue: "", // message to send
            selectedMessageInstance: null,

            phoneValue: ""
            // phoneValue: "(516) 690-7584"
        };
    },
    computed: {
        selectedConversation() {
            if (this.conversations && this.conversations.length) {
                return this.conversations[this.selectedIndex];
            } else {
                return null;
            }
        },
        unreadMessagesCount() {
            return count => {
                return count === null ? "..." : count > 99 ? "..." : count;
            };
        },
        messageTime() {
            let now = moment(new Date());
            return time => {
                time = moment(time).format("YYYY-MM-DD HH:mm:ss");

                const offsetDay = moment(now).diff(moment(time), "day");
                if (offsetDay < 1) {
                    return moment(time).format("hh:mm:ss A");
                } else {
                    return moment(time).format("MM/DD/YYYY");
                }
            };
        },
        friendlyName() {
            return name => {
                return name.replace("messenger", "m");
            };
        },
        hasPrev() {
            return this.selectedMessageInstance && this.selectedMessageInstance.hasPrevPage;
        }
    },
    watch: {
        async selectedConversation(n, o) {
            let nSid = n && n.sid;
            let oSid = o && o.sid;

            if (nSid != oSid) {
                this.allMessagesPaginators = [];

                if (this.selectedConversation) {
                    this.showMessageList = false;
                    // this.search = false  // todo can we not to use this ?
                    await this.loadMessages();
                    this.scrollToBottom();
                }
            }
        }
    },
    methods: {
        setElementStyle() {
            const $conversation = this.$refs["conversation"];
            const padding = getComputedStyle($conversation.offsetParent).padding.replace("px", "") - 0;
            const height = window.innerHeight - $conversation.getBoundingClientRect().top - padding * 2;
            this.conversationHeight = height + "px";
            this.isMobile = window.innerWidth < 500;
        },

        scrollToBottom() {
            setTimeout(() => {
                document.getElementById("anchor").scrollIntoView({ behavior: "smooth" });
            }, 500);
        },

        toggleMessageList() {
            this.showMessageList = !this.showMessageList;
            if (this.showMessageList) {
                this.selectedIndex = -1;
            }
        },

        // ============ load messages ============
        async loadMessages() {
            const messages = await this.selectedConversation.getMessages();
            this.selectedMessageInstance = messages;
            this.messages = messages.items;
            this.setAllMessagesRead();
        },

        async handleGetPrevPage() {
            this.loading = true;
            try {
                const messages = await this.selectedMessageInstance.prevPage();
                this.selectedMessageInstance = messages;
                this.messages = [...messages.items, ...this.messages];
                setTimeout(() => {
                    const element = document.getElementById(messages.items[messages.items.length - 1].sid);
                    element.scrollIntoView({ behavior: "smooth" });
                }, 500);
            } catch (e) {
                this.$store.commit("setMessage", e.message);
            } finally {
                this.loading = false;
            }
        },
        // ============ load messages end ============

        // ============ send message ============
        async handleSelectedImage(e) {
            if (!this.selectedConversation) return;
            const acceptedFiles = e.target.files;
            this.sending = true;
            try {
                await this.selectedConversation.sendMessage({ contentType: acceptedFiles[0].type, media: acceptedFiles[0] });
            } catch (e) {
                this.$store.commit("setMessage", e.message);
            } finally {
                this.sending = false;
            }
        },
        handleClickUploadImage() {
            const $file = this.$refs["file"];
            if ($file.value) {
                $file.value = "";
            }
            $file.click();
        },

        handleSendMessage(e) {
            e.preventDefault();
            const message = this.messageValue;
            if (!message) return;
            this.messageValue = "";
            this.selectedConversation && this.selectedConversation.sendMessage(message);
        },
        // ============ send message end ============

        // ============ add new conversation ============
        async handleAddNewConversation() {
            const phone = (this.phoneValue || "").trim();
            const showTip = () => {
                this.$store.commit("setMessage", "You should enter a valid phone number");
            };
            if (!phone.length) {
                showTip();
            } else {
                const lookupResult = await this.$store.dispatch("crud/post", {
                    api: `conversation/lookup`,
                    data: { phone }
                });
                if (lookupResult && lookupResult.valid) {
                    const conversationSid = await this.createConversation(lookupResult.phoneNumber);
                    this.jumpToConversation(conversationSid);
                    this.hideAddNewConversation();
                } else {
                    showTip();
                }
                this.phoneValue = "";
            }
        },
        hideAddNewConversation() {
            this.$refs["newConversation"].close();
        },
        showAddNewConversation() {
            this.$refs["newConversation"].open();
        },

        async createConversation(phone) {
            let conversation = null;
            try {
                conversation = await this.conversationsClient.getConversationByUniqueName(phone);
            } catch (e) {
                // console.log("conversation not found");
                if (e.message === "Not Found") {
                    conversation = await this.conversationsClient.createConversation({
                        uniqueName: phone,
                        friendlyName: phone,
                        attributes: JSON.stringify({ source: "SMS", phone, proxyAddress: this.TWILIO_PHONE_NUMBER })
                    });
                } else {
                    this.$store.commit("setMessage", e.message);
                }
            }
            // Ensure the conversation is work.
            await this.conversationsClient.getSubscribedConversations();

            if (Array.from(conversation.participants).length < 2) {
                await conversation.addNonChatParticipant(this.TWILIO_PHONE_NUMBER, phone);
            }

            return conversation ? conversation.sid : null;
        },
        // ============ add new conversation end ============

        // ============ del conversation ============
        hideRemoveConversation() {
            this.$refs["delConversation"].close();
        },

        showRemoveConversation() {
            this.$refs["delConversation"].open();
        },

        async handleRemoveConversation(conversationSid) {
            // console.log("handleRemoveConversation");
            await this.$store.dispatch("crud/delete", {
                api: `conversation/${conversationSid}`
            });

            this.hideRemoveConversation();

            if (this.isMobile) {
                this.toggleMessageList();
            }
        },

        // ============ del conversation end ============

        async jumpToConversation(conversationSid) {
            if (conversationSid) {
                await this.conversationsClient.getSubscribedConversations();

                let existConversationIx = this.conversations.findIndex(c => c.sid == conversationSid);
                if (existConversationIx > -1) {
                    this.selectedIndex = existConversationIx;
                }
            }
        },

        // use to refresh the latest data for conversation
        async refreshConversation(conversation) {
            let index = this.selectedIndex;
            if (conversation) {
                index = this.conversations.findIndex(c => c.sid == conversation.sid);
            }
            this.$set(this.conversations, index, this.conversations[index]);
        },

        setAllMessagesRead() {
            if (this.selectedConversation) {
                this.selectedConversation.unreadMessagesCount = 0;
                this.selectedConversation.setAllMessagesRead();
                this.refreshConversation();
            }
        },

        async messageAdded(message, targetConversation) {
            if (targetConversation === this.selectedConversation) {
                // console.log("selectedConversation....");
                this.messages.push(message);
                this.setAllMessagesRead();
                this.scrollToBottom();
            } else {
                targetConversation.unreadMessagesCount = await targetConversation.getUnreadMessagesCount();
                // console.log("unreadMessagesCount", targetConversation.unreadMessagesCount);
                this.refreshConversation(targetConversation);
            }
        },

        async getToken() {
            let result = await this.$store.dispatch("crud/get", {
                api: "conversation/token"
            });
            this.token = result.token;
            this.identity = result.identity;
            this.TWILIO_PHONE_NUMBER = result.TWILIO_PHONE_NUMBER;
        },
        async initConversations() {
            this.loading = true;
            this.conversations = [];
            this.messagesPaginators = [];
            this.messages = [];
            this.messageValue = "";

            if (this.isMobile) {
                this.selectedIndex = -1;
            }

            if (window.conversationsClient) {
                await window.conversationsClient.shutdown();
            }

            this.conversationsClient = await ConversationsClient.create(this.token);

            // connectaion state changed
            this.conversationsClient.on("connectionStateChanged", async state => {
                console.info("connectionStateChanged", state);
                this.connectionState = state;
                if (state === "connecting") {
                }
                if (state === "connected") {
                    this.loading = false;
                }
                if (state === "disconnecting") {
                }
                if (state === "disconnected") {
                }
                if (state === "denied") {
                }
            });

            // conversation joined
            this.conversationsClient.on("conversationJoined", async conversation => {
                console.info("conversationJoined", conversation);

                conversation.on("messageAdded", m => {
                    this.messageAdded(m, conversation);
                });

                // conversation.on("typingStarted", participant => {
                //     this.refreshConversation();
                // });

                // conversation.on("typingEnded", participant => {
                //     this.refreshConversation();
                // });
                const originalSid = this.selectedConversation ? this.selectedConversation.sid : null;
                this.conversations.unshift(conversation);
                this.conversations.sort((a, b) => {
                    let adate = a.lastMessage ? a.lastMessage.dateCreated : a.dateUpdated;
                    let bdate = b.lastMessage ? b.lastMessage.dateCreated : b.dateUpdated;
                    return 1 * (bdate - adate);
                });

                if (this.isInit && originalSid) {
                    const cIndex = this.conversations.findIndex(c => c.sid === originalSid);
                    this.selectedIndex = cIndex;
                }

                let unreadMessagesCount = await conversation.getUnreadMessagesCount();
                conversation.unreadMessagesCount = unreadMessagesCount;
                // console.log(conversation.friendlyName, unreadMessagesCount);
                this.refreshConversation(conversation);

                if (!this.isInit) {
                    clearTimeout(this.initTimer);
                    this.initTimer = setTimeout(() => {
                        this.isInit = true;
                    }, 1000);
                }
            });

            this.conversationsClient.on("conversationLeft", async thisConversation => {
                console.info("conversationLeft", thisConversation);
                this.conversations = this.conversations.filter(it => it.sid !== thisConversation.sid);
                this.conversations.sort((a, b) => {
                    let adate = a.lastMessage ? a.lastMessage.dateCreated : a.dateUpdated;
                    let bdate = b.lastMessage ? b.lastMessage.dateCreated : b.dateUpdated;
                    return 1 * (bdate - adate);
                });
            });

            window.conversationsClient = this.conversationsClient;
        },
        async init() {
            this.$store.commit("setLoading", true);
            this.setElementStyle();

            await this.getToken();
            await this.initConversations();

            this.$store.commit("setLoading", false);

            this.initResize();
            this.initFbBtn();
        },

        resizeAction() {
            clearTimeout(this.resizeTimer);
            this.resizeTimer = setTimeout(() => {
                console.log("resiee action");
                this.setElementStyle();
            }, 500);
        },

        initResize() {
            window.addEventListener("resize", this.resizeAction);
        },

        initFbBtn(flag) {
            const fbRoot = document.getElementById("fb-root");
            if (fbRoot) {
                fbRoot.style.display = flag ? "block" : "none";
            }
        }
    },
    mounted() {
        this.init();
    },
    async beforeDestroy() {
        if (window.conversationsClient) {
            await window.conversationsClient.shutdown();
        }
        window.removeEventListener("resize", this.resizeAction);
        this.initFbBtn(true);
    }
};
</script>

<style lang="less" scoped>
@primay: #0084ff;
.conversation {
    background-color: white;
    overflow: hidden;
    .wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
        .msg-list-btn {
            display: none;
        }
    }
    .main {
        position: relative;
        display: flex;
        flex: 1;
        overflow: hidden;

        .sidebar {
            width: 300px;
            min-width: 300px;
            overflow-y: auto;
            box-shadow: -5px 0 10px rgba(0, 0, 0, 0.25);
            background-color: white;
            // border-right: 1px solid rgba(0, 0, 0, 0.25);
            .summary-item {
                cursor: pointer;
                border-bottom: 1px solid #cecece;
                &.unread {
                    font-weight: bold;
                    color: black;
                    background-color: rgba(0, 0, 0, 0.1);
                }
                &.active {
                    background-color: @primay;
                    .name {
                        font-weight: bold;
                        color: #fff;
                        word-break: break-word;
                        white-space: pre-wrap;
                    }
                    .time {
                        color: #cecece;
                    }
                }
            }
        }

        .content {
            min-width: 400px;
            flex: 1;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            background-color: white;
            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: @primay;
                padding: 10px;
                box-shadow: 10px -2px 10px rgba(0, 0, 0, 0.14);
            }
            .messages {
                .pre-page {
                    display: flex;
                    justify-content: center;
                    color: #aeaeae;
                }
                flex: 1;
                overflow: auto;
            }
        }
    }

    .footer {
        &.input-wrap {
            .md-input-container {
                margin: 0;
            }
            /deep/ .md-input-container.md-input-focused .md-icon:not(.md-icon-delete) {
                color: white;
            }

            /deep/ .md-input-container:after {
                height: 0;
            }

            .custom_button /deep/ .material-icons {
                color: white;
            }
            .img-input {
                position: absolute;
                width: 1px;
                height: 1px;
            }
        }
    }

    .badge {
        position: relative;
        top: 0;
        bottom: 0;
        right: 0;
        transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        right: -4px;
        font-size: 10px;
        font-style: normal;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        color: #fff;
        pointer-events: none;
        background-color: #448aff;
        z-index: 7;
    }
}

@media (max-width: 500px) {
    .full {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .conversation {
        .wrap {
        }
        .main {
            .sidebar {
                .full();
                width: auto;
                z-index: 1;
            }
            .content {
                .full();
                min-width: unset;
                z-index: 10;

                .messages {
                    /deep/ ul {
                        padding: 0;
                    }
                }

                .footer {
                    .md-input-container {
                        .custom_button {
                            .md-icon {
                                top: 4px;
                            }
                            /deep/ .md-icon {
                                top: 4px;
                            }
                        }
                    }
                }
            }
        }
        &.mobile {
            .wrap {
                .msg-list-btn {
                    display: block;
                }
                .new-room-btn {
                    display: none;
                }
            }
            .main {
                .sidebar {
                    &.show {
                        z-index: 20;
                    }
                }
            }
        }
    }
}
</style>
