<template>
    <li :class="{ received_msg: !incoming, outgoing_msg: incoming }" :id="message.sid">
        <div :class="{ received_withd_msg: !incoming, sent_msg: incoming }">
            <div>
                <md-icon>sms</md-icon><strong>{{ message.author }}</strong>
                <br />

                <div class="media" :class="{ placeholder: !mediaUrl }" v-if="hasMedia">
                    <md-spinner md-indeterminate v-if="!mediaUrl && !mediaDownloadFailed"></md-spinner>

                    <div v-if="mediaDownloadFailed" style="display:flex;flex-direction:column;">
                        <md-icon>warning</md-icon>
                        <p>Faild to load media</p>
                    </div>

                    <div class="media_icon" v-if="!mediaDownloadFailed && mediaUrl">
                        <div class="picture_preview" :style="{ backgroundImage: 'url(' + mediaUrl + ')' }" @click="viewImage"></div>
                    </div>
                </div>

                {{ message.body }}
            </div>
            <span class="time_date">
                {{ message.state.timestamp | date }}
            </span>
        </div>
        <md-dialog md-open-from="#custom" md-close-to="#custom" ref="dialog1" style="z-index: 12347;">
            <md-dialog-content>
                <div class="picture_container">
                    <img :src="mediaUrl" style="width:100%;height:100%;" />
                </div>
            </md-dialog-content>

            <md-dialog-actions>
                <md-button class="md-primary" @click="closeDialog('dialog1')">Close</md-button>
            </md-dialog-actions>
        </md-dialog>
    </li>
</template>

<script>
export default {
    props: {
        direction: {
            type: String,
            default: "incoming"
            // outgoing
        },
        message: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            mediaDownloadFailed: false,
            mediaUrl: null
        };
    },
    computed: {
        hasMedia() {
            return this.message.type === "media";
        },
        incoming() {
            return this.direction === "incoming";
        }
    },
    methods: {
        closeDialog() {
            this.$refs["dialog1"].close();
        },
        viewImage() {
            this.$refs["dialog1"].open();
        }
    },
    mounted() {
        if (this.hasMedia) {
            this.message.media
                .getContentTemporaryUrl()
                .then(url => {
                    this.mediaUrl = url;
                })
                .catch(e => {
                    this.mediaDownloadFailed = true;
                });
        }
    }
};
</script>

<style lang="less" scoped>
.md-icon {
    font-size: 20px;
}
.incoming_msg_img {
    display: inline-block;
    width: 6%;
}
.received_msg {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    width: 92%;
}
.received_withd_msg > div {
    background: #d8d8d8 none repeat scroll 0 0;
    border-radius: 3px;
    color: #646464;
    font-size: 14px;
    margin: 0;
    padding: 5px 10px 5px 12px;
    width: fit-content;
}
.time_date {
    color: #747474;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
}
.received_withd_msg {
    max-width: 50%;
}

.sent_msg > div {
    background: #0084ff none repeat scroll 0 0;
    border-radius: 3px;
    font-size: 14px;
    margin: 0;
    color: #fff;
    padding: 5px 10px 5px 12px;
    // width: 100%;
    width: fit-content;
}
.outgoing_msg {
    overflow: hidden;
    // margin: 26px 0 26px;
    padding: 0 10px 0 0px;
}
.sent_msg {
    display: inline-block;
    float: right;
    max-width: 50%;
}
.input_msg_write input {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    color: #4c4c4c;
    font-size: 15px;
    min-height: 48px;
    width: 100%;
}

.image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.picture_preview {
    width: 250px;
    height: 250px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.picture_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.media {
    border-radius: 4px;
    cursor: pointer;
    margin: 4px;
    display: flex;
    width: 220px;
    height: 220px;
    justify-content: center;
    align-items: center;
}

.placeholder {
    background: rgba(22, 46, 52, 0.58);
}

.medias {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-flow: wrap;
}

.media_icon {
    margin: 4px;
    position: relative;
    border-radius: 4px;
    top: 0;
    overflow: hidden;
    height: 220px;
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-basis: 220px;
}

@media (max-width: 600px) {
    .received_withd_msg {
        max-width: 75%;
    }
    .sent_msg {
        max-width: 75%;
    }
}
</style>
